import { Box } from "@chakra-ui/react";
import React from "react";
import EventUpdateSection from "./EventUpdateSection";
import UpcomingEvent from "./UpcomingEvent";

const Event = () => {
  return (
    <Box w="100%" bgColor={"#11232F"}>
      <UpcomingEvent />
      <EventUpdateSection />
    </Box>
  );
};

export default Event;
