import React from "react";
import DABMagazine from "../../Components/DAB_Magazine/DABMagazine";
const DABMagazinePage = React.memo((props) => {
  return (
    <>
      <DABMagazine />
    </>
  );
});

export default DABMagazinePage;
