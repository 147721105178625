import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";

const OpportunityData = [
  {
    Image: "img/Opp1.svg",
    Name: "Networking ",
    AOSDuration: 300,
  },
  {
    Image: "img/Opp2.svg",
    Name: "Collaboration & Business opportunity ",
    AOSDuration: 600,
  },
  {
    Image: "img/Opp3.svg",
    Name: "3rd party referances",
    AOSDuration: 900,
  },
  {
    Image: "img/Opp4.svg",
    Name: "Industrial insights ",
    AOSDuration: 300,
  },
  {
    Image: "img/Opp5.svg",
    Name: "Personal Development ",
    AOSDuration: 600,
  },
  {
    Image: "img/Opp6.svg",
    Name: "Fraud alert",
    AOSDuration: 900,
  },
  {
    Image: "img/Opp7.svg",
    Name: "Access to resources & services ",
    AOSDuration: 300,
  },
  {
    Image: "img/Opp8.svg",
    Name: "Tackle karagir / common issues",
    AOSDuration: 600,
  },
  {
    Image: "img/Opp9.svg",
    Name: "Knowledge sharing",
    AOSDuration: 900,
  },
];

const OpportunitySection = () => {
  return (
    <Box w="100%" mt="5" h={"auto"} bgGradient="linear(to-r, #4845DB, #ACEAE8)">
      <Box w={{ base: "90%", md: "60%" }} py="12" h={"100%"} m="auto">
        <Text
          textAlign={"center"}
          color={"#FFFFFF"}
          fontSize={"14px"}
          lineHeight={"normal"}
          fontWeight={500}
          data-aos="fade-up"
        >
          Unlock Opportunities by Joining Us
        </Text>
        <Text
          textAlign={"center"}
          pt="2"
          color={"#FFFFFF"}
          fontSize={"50px"}
          lineHeight={"normal"}
          fontWeight={500}
          data-aos="fade-up"
        >
          Some key benefits of joining the association includes
        </Text>

        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={10}
          m={"auto"}
          mt={{ base: "14", md: "10" }}
        >
          {OpportunityData.map((el, index) => (
            <Box
              key={index}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifySelf={"center"}
              gap={{ base: 5, md: 2 }}
              data-aos="fade-up"
              data-aos-duration={el.AOSDuration}
            >
              <Image
                w={{ base: "150px", md: "70px" }}
                src={el.Image}
              />
              <Text
                color="#FFFFFF"
                fontSize={{ base: "20px", md: "15px" }}
                fontWeight="600"
                textAlign={"center"}
              >
                {el.Name}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default OpportunitySection;
