import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

const OurMissionData = [
  {
    Image: "img/MissionImage1.png",
    Description:
      "“Established in 2016, our Diamond Association is committed to empowering and uniting the diamond industry. Our mission is to promote ethical practices, foster innovation, provide valuable resources, and create a strong network to ensure the growth and sustainability of our members and the diamond industry as a whole”",
    Name: "Mr Kiran Mehta",
    Timeline: "President : 2022 - Present ",
  },
];

const OurMission = () => {
  return (
    <Box w="100%" h={"auto"} mt="16">
      {OurMissionData.map((el, index) => (
        <Box
          w={{ base: "90%", md: "70%" }}
          h="100%"
          m="auto"
          display={"flex"}
          flexDirection={{ base: "column", md: "row" }}
          gap={10}
        >
          <Box w={{ base: "100%", md: "50%" }}>
            <Image w="100%" borderRadius={"10px"} src={el.Image} />
          </Box>
          <Box
            w={{ base: "100%", md: "50%" }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Text
              color="#11232F"
              fontSize={{ base: "20px", md: "28px" }}
              fontWeight="600"
            >
              Our mission
            </Text>
            <Text
              color="#11232F"
              fontSize={{ base: "17px", md: "15px" }}
              fontWeight="500"
              fontStyle={"italic"}
              mt="4"
            >
              {el.Description}
            </Text>
            <Text mt="4" color="#11232F" fontSize={"17px"} fontWeight="600">
              {el.Name}
            </Text>
            <Text color="#11232F" fontSize={"14px"} fontWeight="400">
              {el.Timeline}
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default OurMission;
