import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const Top10DiamondsData1 = [
  {
    ImageUrl: "/img/Top10Diamond1.svg",
    Name: "Koh-i-Noor",
    Desc: "One of the oldest and most famous precious jewels in the world as its history goes back more than 5,000 years.",
    Weight: "105.6 carats",
  },
  {
    ImageUrl: "/img/Top10Diamond2.svg",
    Name: "Hope Diamond",
    Desc: "Like the Koh-i-Noor, the Hope diamond is a very famous jewel which has had owners dating back almost 400 years.",
    Weight: "45.52 carats",
  },
];

const Top10DiamondsData2 = [
  {
    ImageUrl: "/img/Top10Diamond3.svg",
    Name: "Daria-i-Noor",
    Desc: "One of the largest cut diamonds in the world and one of the rarest. That is down to its pale pink colour.",
    Weight: "182 carats",
    AOSDuration: 500,
  },
  {
    ImageUrl: "/img/Top10Diamond4.svg",
    Name: "Dresden Green",
    Desc: "The Dresden Green diamond is the largest natural green diamond to have ever been discovered.",
    Weight: "40.7 carats",
    AOSDuration: 800,
  },
  {
    ImageUrl: "/img/Top10Diamond5.svg",
    Name: "Moon of Baroda",
    Desc: "One of the most recognised diamonds internationally when it made its way into America.",
    Weight: "unknown",
    AOSDuration: 1100,
  },
  {
    ImageUrl: "/img/Top10Diamond6.svg",
    Name: "Florentine Diamond",
    Desc: "One of the more obscure Indian-origin stones as it has been missing since the 1920s.",
    Weight: "unknown",
    AOSDuration: 1400,
  },
  {
    ImageUrl: "/img/Top10Diamond7.svg",
    Name: "Jacob Diamond",
    Desc: "It is the fifth largest diamond in the world, However, before it was cut, it was over 400 carats (80g).",
    Weight: "184.75 carats",
    AOSDuration: 500,
  },
  {
    ImageUrl: "/img/Top10Diamond8.svg",
    Name: "Noor-ul-Ain",
    Desc: "One of the largest pink diamonds in the world, It is believed to be the second section of the Great Table Diamond.",
    Weight: "unknown",
    AOSDuration: 800,
  },
  {
    ImageUrl: "/img/Top10Diamond9.svg",
    Name: "Orlov Diamond",
    Desc: "The Orlov diamond is of Indian origin but it has spent most of its time in Russia where it is displayed today.",
    Weight: "unknown",
    AOSDuration: 1100,
  },
  {
    ImageUrl: "/img/Top10Diamond10.svg",
    Name: "Shah Diamond",
    Desc: "During approximately 1450, the Shah diamond was discovered in the Golconda mines, Central India.",
    Weight: "unknown",
    AOSDuration: 1400,
  },
];

const Top10Diamonds = () => {
  return (
    <Box
      w={{ base: "90%", md: "85%" }}
      style={{ margin: "auto", paddingBottom: "50px" }}
      pt={{ base: "30px", md: "50px" }}
    >
      <Box>
        <Text
          color="#FFF"
          fontSize={{ base: "30px", md: "40px" }}
          fontWeight={{ base: "500", md: "700" }}
          textAlign={"center"}
        >
          Top 10 Diamonds originating from India
        </Text>
      </Box>

      <Box
        mt={4}
        display={"flex"}
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={"space-between"}
        gap={4}
        data-aos="fade-up"
        data-aos-duration={1000}
      >
        {Top10DiamondsData1?.map((el, index) => (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={4}
            key={index}
            width={{ base: "100%", md: "40%" }}
          >
            <Box w="40%">
              <Text
                fontSize={{ base: "20px", md: "25px" }}
                fontWeight={500}
                color={"#FFF"}
              >
                {el.Name}
              </Text>
              <Image mt="1" src={el.ImageUrl} alt={el.Name} />
              <Text
                fontSize={{ base: "15px", md: "18px" }}
                fontWeight={300}
                color={"#FFF"}
                mt="1"
              >
                Weight:{el.Weight}
              </Text>
            </Box>
            <Box w="50%">
              <Text
                fontSize={{ base: "15px", md: "20px" }}
                fontWeight={300}
                color={"#FFF"}
              >
                {el.Desc}
              </Text>
            </Box>
          </Box>
        ))}
      </Box>

      <SimpleGrid
        columns={["1", "1", "2", "3", "4"]}
        spacing={{ base: 14, md: 20 }}
        m="auto"
        my="20"
      >
        {Top10DiamondsData2?.map((el, index) => (
          <Box
            key={index}
            m="auto"
            w={{ base: "250px", md: "197px" }}
            data-aos="fade-up"
            data-aos-duration={el.AOSDuration}
          >
            <Image w={{ base: "100%", md: "197px" }} src={el.ImageUrl} />

            <Text
              mt="2"
              color={"#FFF"}
              fontWeight={500}
              fontSize={"20px"}
              noOfLines={1}
            >
              {el.Name}
            </Text>
            <Text
              mt="2"
              color={"#FFF"}
              fontWeight={300}
              fontSize={"15px"}
              noOfLines={4}
            >
              {el.Desc}
            </Text>
            <Text mt="4" color={"#FFF"} fontWeight={300} fontSize={"15px"}>
              Weight: {el.Weight}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Top10Diamonds;
