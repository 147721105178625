import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const EventCardData = [
  {
    Image: "img/Event1.png",
    Description:
      "Addressing Challenges in Implementing Mandatory HUID (Unique Identification) Requirements.",
    Date: "29-march-2023",
    AOSDuration: 500,
  },
  {
    Image: "img/Event2.png",
    Description:
      "Join Ahimsa Run: Promoting Peace and Non-violence through a Transformative Event",
    Date: "29-march-2023",
    AOSDuration: 800,
  },
  {
    Image: "img/Event3.png",
    Description:
      "Roots Gems and Manufacturer’s Show: Unveiling the Essence of Fine Jewellery Craftsmanship.",
    Date: "16-19-dec-2023",
    AOSDuration: 1100,
  },
  {
    Image: "img/Event4.png",
    Description:
      "Overseas Extravaganza: Hong Kong Jewellery and Gems Show, Unveiling Global Elegance and Craftsmanship.",
    Date: "23-28-sept-2023",
    AOSDuration: 1400,
  },
];

const EventCardSection = () => {
  return (
    <Box w="100%" h={{ base: "auto" }} p="10" data-aos-duration="3000"
    data-aos="fade-up"
      mt="10" bgColor={"#11232F"}>
      <Box w={{ base: "80%", md: "90%" }} style={{margin:'50px auto'}}>
        <Text
          textAlign={"center"}
          color={"#FCFCFC"}
          fontSize={"14px"}
          lineHeight={"normal"}
          fontWeight={500}
          data-aos="fade-up"
        >
          Watch this Space for latest happenings
        </Text>
        <Text
          textAlign={"center"}
          pt="2"
          color={"#F9F9F9"}
          fontSize={"50px"}
          lineHeight={"normal"}
          fontWeight={500}
          data-aos="fade-up"
        >
          Event Updates
        </Text>

        {/* Event Card Start */}

        <SimpleGrid
          columns={["1","1","2","2","4"]}
          spacing={10}
          m={"auto"}
          mt={{ base: "14", md: "10" }}
        >
          {EventCardData.map((el, index) => (
            <Box
              key={index}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifySelf={"center"}
              gap="4"
              bgGradient="linear(to-b, #00F1E9, #00FD9A)"
              borderRadius={"10px"}
              p="4"
              // h={{ base: "400px", md: "300px" }}
              data-aos="fade-up"
              data-aos-duration={el.AOSDuration}
            >
              <Image w="100%" src={el.Image} />
              <Text
                color="#000000"
                fontSize={{ base: "18px", md: "15px" }}
                fontWeight="600"
                textAlign={"center"}
              // noOfLines={{ base: 2, md: 3 }}
              >
                {el.Description}
              </Text>
              <Text
                color="#000000"
                fontSize={{ base: "15px", md: "12px" }}
                fontWeight="600"
                textAlign={"center"}
                textTransform={"uppercase"}
              >
                {el.Date}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default EventCardSection;
