import {
    GetAPICALL,
    PostAPICALL,
  } from "../../Config/Functions/getFun";
  
  import { toast } from "react-toastify";
import { GET_EVENT_ERROR, GET_EVENT_LOADING, GET_EVENT_SUCCESS } from "../actiontypes/event.types";

  
  export const EventADDAPI = (payload) => (dispatch) => {
    PostAPICALL(`companies`, payload)
      .then((r) => {
        toast.success("Event Added Successfully.", {
          position: "top-left",
          theme: "colored",
          autoClose: 3000,
        });
        dispatch(EventAPI());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Event Not Added.", {
          position: "top-left",
          theme: "colored",
          autoClose: 3000,
        });
      });
  };
  
  export const EventAPI = () => (dispatch) => {
    dispatch({ type: GET_EVENT_LOADING });
    GetAPICALL(`events`)
      .then((r) => {
        dispatch({ type: GET_EVENT_SUCCESS, payload: r.results });
      })
      .catch(() => {
        dispatch({ type: GET_EVENT_ERROR });
      });
  };
  

  
  
  
  
  