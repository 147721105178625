import { Box, Text } from "@chakra-ui/react";
import React from "react";

const TextDescriptionSection = () => {
  return (
    <Box w="100%">
      <Box py={{ base: 14, md: 20 }} w={{ base: "80%", md: "66%" }} m="auto">
        <Text
          textAlign={"center"}
          fontSize={{ base: "22px", md: "25px" }}
          fontWeight={600}
          color="#11232F"
        >
          “Utilize the extensive experience of our association members, who have
          been in the diamond industry for decades, to connect and seek guidance
          for addressing any issues or challenges you may be encountering”
        </Text>
      </Box>
    </Box>
  );
};

export default TextDescriptionSection;
