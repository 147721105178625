import { Box } from "@chakra-ui/react";
import React from "react";
import TopSection from "./TopSection";
import OurMission from "./OurMission";
import CCTeamSection from "./CCTeamSection";
import ImpactSection from "./ImpactSection";
import LeadersSection from "./LeadersSection";

const AboutUs = () => {
  return (
    <Box>
      <TopSection />
      <OurMission />
      <CCTeamSection />
      <ImpactSection />
      <LeadersSection />
    </Box>
  );
};

export default AboutUs;
