import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import thunk from "redux-thunk";
import companyReducerFun from "./reducer/company.reducer";
import eventReducerFun from "./reducer/event.reducer";

const rootReducer = combineReducers({
  company: companyReducerFun,
  event:eventReducerFun,
});

const Store = legacy_createStore(rootReducer, applyMiddleware(thunk));

export default Store;
