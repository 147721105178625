import React from "react";
import {
  Box,
  Input,
  FormLabel,
  HStack,
  Button,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";

const OwnerDetailsForm = ({ index, ownerDetails, onAddOwner, onDeleteOwner, onOwnerDetailsChange }) => {
  const handleOwnerDetailsChange = (event) => {
    const { name, value } = event.target;
    onOwnerDetailsChange(index, {
      ...ownerDetails,
      [name]: value,
    });
  };

  const handleAddOwner = () => {
    onAddOwner();
  };

  return (
    <Box
      bgColor={"#F4F4F4"}
      boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
      borderRadius={"10px"}
      py="4"
      px="6"
      mt="4"
      data-aos="fade-up"
      data-aos-duration="1500"
    >
      <form>
        <Text color={"#11232F"} fontSize={"12px"} fontWeight={500}>
          Owner’s Details :
        </Text>
        <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={10} mt="4">
          <Box>
            <FormLabel color={"#ABABAB"} fontSize={"14px"} fontWeight={500}>
              Owner’s Name
            </FormLabel>
            <Input
              focusBorderColor="#ABABAB"
              borderRadius={"5px"}
              bgColor={"#FFFFFF"}
              type="text"
              name="name"
              value={ownerDetails.name}
              onChange={handleOwnerDetailsChange}
            />
          </Box>
          <Box>
            <FormLabel color={"#ABABAB"} fontSize={"14px"} fontWeight={500}>
            Phone
            </FormLabel>
            <Input
              focusBorderColor="#ABABAB"
              borderRadius={"5px"}
              bgColor={"#FFFFFF"}
              type="number"
              name="phone"
              value={ownerDetails.phone}
              onChange={handleOwnerDetailsChange}
            />
          </Box>
          <Box>
            <FormLabel color={"#ABABAB"} fontSize={"14px"} fontWeight={500}>
              Mail ID
            </FormLabel>
            <Input
              focusBorderColor="#ABABAB"
              borderRadius={"5px"}
              bgColor={"#FFFFFF"}
              type="email"
              name="email"
              value={ownerDetails.email}
              onChange={handleOwnerDetailsChange}
            />
          </Box>
          <Box>
            <FormLabel color={"#ABABAB"} fontSize={"14px"} fontWeight={500}>
              ALT Contact no
            </FormLabel>
            <Input
              focusBorderColor="#ABABAB"
              borderRadius={"5px"}
              bgColor={"#FFFFFF"}
              type="number"
              name="altContactNo"
              value={ownerDetails.altContactNo}
              onChange={handleOwnerDetailsChange}
            />
          </Box>
        </SimpleGrid>

        <HStack spacing={4}>
          <Button
            mt="4"
            leftIcon={<AddIcon fontSize={"12px"} />}
            color={"#ABABAB"}
            onClick={handleAddOwner}
          >
            Add owner
          </Button>
          {index > 0 && (
            <Button
              mt="4"
              leftIcon={<DeleteIcon fontSize={"12px"} />}
              color={"#ABABAB"}
              onClick={() => onDeleteOwner(index)}
            >
              Delete owner
            </Button>
          )}
        </HStack>
      </form>
    </Box>
  );
};

export default OwnerDetailsForm;
