import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FaSearch, FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CompanyAPI } from "../../Redux/action/company.action";
import AOS from "aos";
import "aos/dist/aos.css";
import { CloseIcon } from "@chakra-ui/icons";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

AOS.init();

const SearchSection2 = () => {
  const [voiceSearchEnabled, setVoiceSearchEnabled] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const dispatch = useDispatch();
  const { company: CompanyData, loading } = useSelector(
    (state) => state.company
  );
  // console.log("CompanyData", CompanyData);

  const location = useLocation();
  const searchQuery2 = new URLSearchParams(location.search).get("searchQuery");

  const [searchQuery, setSearchQuery] = useState(
    searchQuery2 ? searchQuery2 : ""
  );
  const [searchResultFound, setSearchResultFound] = useState(true);


  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      toast.warn("Please enter a search query.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      dispatch(CompanyAPI(searchQuery));
      setSearchPerformed(true);
    }
  };
  const handleClearData = () => {
    setSearchQuery(""); // Clear the search query
    setSearchPerformed(false); // Reset searchPerformed to false
    dispatch(CompanyAPI("")); // Fetch and display API data with an empty query
  };

  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/profile/${id}`);
  };


  const { transcript, listening, resetTranscript } = useSpeechRecognition();

  // const handleVoiceSearch = () => {
  //   if (!isListening) {
  //     const SpeechRecognition =
  //       window.SpeechRecognition || window.webkitSpeechRecognition;
  //     const recognition = new SpeechRecognition();

  //     recognition.lang = "en-US";
  //     recognition.start();

  //     recognition.onstart = () => {
  //       setIsListening(true);
  //     };

  //     recognition.onresult = (event) => {
  //       const transcript = event.results[0][0].transcript;
  //       setSearchQuery(transcript);
  //       dispatch(CompanyAPI(transcript));
  //       setSearchPerformed(true); //clear data button
  //       // handleSearch();
  //     };

  //     recognition.onend = () => {
  //       setIsListening(false);
  //     };

  //     setRecognition(recognition);
  //   } else {
  //     recognition.stop();
  //   }
  // };

  useEffect(() => {
    const recognitionAvailable =
      "SpeechRecognition" in window || "webkitSpeechRecognition" in window;
    setVoiceSearchEnabled(recognitionAvailable);
    dispatch(CompanyAPI(searchQuery));
  }, []);

  useEffect(() => {
    console.log(transcript);
    setSearchQuery(transcript);
    dispatch(CompanyAPI(transcript));
  }, [dispatch, transcript])


  return (
    <Box
      w={{ base: "100%", md: "90%" }}
      m={"auto"}
      my={{ base: "50px", md: "150px" }}
    >
      <Text
        textAlign={"center"}
        color={{ base: "#11232E", md: "#000000" }}
        fontWeight={{ base: 600, md: 600 }}
        fontSize={{ base: "16px", md: "20px" }}
        ml={{ base: "none" }}
      >
        Uniting the Diamond Community on Our Website
      </Text>
      <Box display={"flex"} mt={{ base: "10", md: "14" }}>
        <Box
          display={"flex"}
          justifyContent={{ base: "none", md: "end" }}
          w={{ base: "80%", md: "68%" }}
          m={"auto"}
        >
          <Box>
            <Image
              mt={"-20px"}
              h={"100px"}
              src="img/Diamond1.png"
              alt="DiamondLogo"
              display={{ base: "none", md: "block" }}
            />
          </Box>
          <Box>
            <Text
              color={{ base: "#11232E", md: "#000000" }}
              fontSize={"50px"}
              fontWeight={{ base: 700, md: 800 }}
              textAlign={{ base: "center", md: "center" }}
            >
              Sparkling pathway to a{" "}
              <span style={{ color: "#6BD797" }}>Brighter</span> future..
            </Text>

            {/* Search section start */}
            <Box
              w={{ base: "100%" }}
              display={"flex"}
              flexDirection={{ base: "column", md: "row" }}
              gap="4"
              mt={4}
            >
              <InputGroup w={{ base: "100%", md: "80%" }}>
                <Input
                  h="49px"
                  borderRadius={"26px"}
                  fontFamily="Poppins"
                  border="1px solid #EFEEEA"
                  bgColor={"#FFFCFB"}
                  type="text"
                  value={searchQuery}
                  onChange={handleInputChange}
                  placeholder="eg. “Current president”"
                />
                <InputRightElement width="3.5rem" mt={1}>
                  {/* {voiceSearchEnabled && (
                    <Button
                      h="38px"
                      w="38px"
                      borderRadius={"50%"}
                      bgColor={"#11232F"}
                      _hover={"#11232F"}
                      color="#FFFFFF"
                      onClick={handleVoiceSearch}
                      disabled={isListening}
                    >
                      {isListening ? (
                        <FaMicrophoneSlash style={{ fontSize: "20px" }} />
                      ) : (
                        <FaMicrophone style={{ fontSize: "20px" }} />
                      )}
                    </Button>
                  )} */}

                  {listening ? (
                    <Button
                      h="38px"
                      w="38px"
                      borderRadius={"50%"}
                      bgColor={"#11232F"}
                      _hover={"#11232F"}
                      color="#FFFFFF"
                      onClick={SpeechRecognition.stopListening}
                      disabled={listening}
                    >
                      <FaMicrophoneSlash style={{ fontSize: "20px" }} />
                    </Button>
                  ) : (
                    <Button
                      h="38px"
                      w="38px"
                      borderRadius={"50%"}
                      bgColor={"#11232F"}
                      _hover={"#11232F"}
                      color="#FFFFFF"
                      onClick={SpeechRecognition.startListening}
                      disabled={listening}
                    >
                      <FaMicrophone style={{ fontSize: "20px" }} />
                    </Button>
                  )}
                </InputRightElement>
              </InputGroup>

              <Button
                h="49px"
                w={{ base: "100%", md: "20%" }}
                bgColor="#11232F"
                color="#FFFFFF"
                _hover={"#11232F"}
                fontSize={{ base: "22px", md: "15px" }}
                fontWeight={500}
                fontFamily="Poppins"
                borderRadius="42px"
                display="flex"
                alignItems="center"
                justifyContent={{ base: "center", md: "space-between" }}
                onClick={handleSearch}
                rightIcon={<FaSearch style={{ marginLeft: "10px" }} />}
              >
                Search
              </Button>
            </Box>
            <Text
              color="#B9B9B9"
              fontSize="10px"
              fontWeight={500}
              fontFamily="Poppins"
              mt="5px"
              display={{ base: "none", md: "block" }}
            >
              Manufacturers - Wholesalers - Gemstones - Job work
            </Text>
          </Box>
        </Box>
      </Box>

      {/* Comapny Data Started */}
      {loading ? (
        <Box textAlign={"center"} mt="10">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Box>
      ) : (
        <Box>
          {/* <Box>
            {searchPerformed && (
              <Box
                w={"80%"}
                margin={"auto"}
                display={"flex"}
                justifyContent={{ base: "center", md: "flex-start" }}
              >
                <Button
                  mt="2"
                  h="49px"
                  w={{ base: "100%", md: "20%" }}
                  colorScheme="green"
                  fontSize={{ base: "22px", md: "15px" }}
                  fontWeight={600}
                  fontFamily="Poppins"
                  borderRadius="42px"
                  textAlign={"center"}
                  onClick={
                    handleClearData
                  }
                  leftIcon={<CloseIcon />}
                >
                  Clear Data
                </Button>
              </Box>
            )}
          </Box> */}

          <SimpleGrid
            columns={["1", "1", "2", "3", "4"]}
            spacing={10}
            w={{ base: "90%", md: "80%" }}
            m="auto"
            my="20"
          >
            {CompanyData?.map((el) => (
              <Box
                key={el.id}
                m="auto"
                w={{ base: "250px", md: "197px" }}
                data-aos="fade-up"
                data-aos-duration={1000}
              >
                {el.logo && el.logo.length > 0 ? (
                  <Image w={{ base: "100%", md: "197px" }} src={el.logo} />
                ) : (
                  <Image
                    w={{ base: "100%", md: "197px" }}
                    src="img/placeholder.png"
                  />
                )}
                <Text
                  mt="2"
                  color={"#000000"}
                  fontWeight={500}
                  fontSize={"20px"}
                  noOfLines={1}
                >
                  {el.name}
                </Text>
                <Button
                  mt="2"
                  h={{ base: "40px", md: "31px" }}
                  w={{ base: "100%", md: "196px" }}
                  color={"#000000"}
                  fontWeight={600}
                  fontSize={{ base: "18px", md: "12px" }}
                  bgGradient={"linear(to-r,#ADEEC9, #A9FAF3)"}
                  _hover={{ bgColor: "#A8FAF3" }}
                  onClick={() => handleClick(el.id)}
                >
                  Get company details
                </Button>
              </Box>
            ))}
          </SimpleGrid>

          {CompanyData?.length === 0 && searchResultFound && (
            <Box textAlign="center" mx="auto" my={2}>
              <Text color="red" fontSize="18px">
                No companies found with the given search query .
              </Text>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SearchSection2;
