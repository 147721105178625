import {
  Box,
  Button,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { LiaPhoneSolid } from "react-icons/lia";
import { TiLocation } from "react-icons/ti";
import { BiSolidWatch } from "react-icons/bi";
import { TbHandClick } from "react-icons/tb";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import MembershipForm from "./MembershipForm";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const ListHeader = ({ children }) => {
  return (
    <Text
      fontWeight={"600"}
      fontSize={{ base: "18px", md: "14px" }}
      color="#000000"
      mb={2}
    >
      {children}
    </Text>
  );
};

const RoundedIcon = ({ children }) => (
  <Box
    bg="#656565 "
    borderRadius="full"
    display="flex"
    alignItems="center"
    justifyContent="center"
    w="25px"
    h="25px"
  >
    {children}
  </Box>
);

const ContactUsTopSection = () => {
  const [showForm, setShowForm] = useState(false);

  const handleBecomeMemberClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <Box w="100%" position={{ base: "none", md: "relative" }}>
      <Image
        right={0}
        top={"150px"}
        display={{ base: "none", md: "block" }}
        position={{ base: "none", md: "absolute" }}
        src="img/diamond_Tile1.png "
      />
      <Box w={{ base: "90%", md: "60%" }} m="auto" pt="16">
        <Box
          display={"flex"}
          flexDirection={"column"}
          m={"auto"}
          textAlign={"center"}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <Box>
            <Image
              h={{ base: "200px", md: "297px" }}
              w={{ base: "250px", md: "332px" }}
              m={"auto"}
              src={"img/DAB_LOGO2.png"}
              alt="HomeLogo"
            />
          </Box>
          <Box>
            <Text fontSize={"14px"} color={"#0077bd"} fontWeight={500}>
              Diamond Association Bangalore
            </Text>
          </Box>

          <Text fontSize={"12px"} color="#0077bd">
            Shine Together... Grow Together
          </Text>
        </Box>

        <Box mt="100px" data-aos="fade-up" data-aos-duration="1500">
          <Text
            fontSize={{ base: "30px", md: "40px" }}
            fontWeight="800"
            bgGradient="linear(to-r, #01FDA0 0%, #00F1E9 100%)"
            bgClip="text"
            fontStyle={"normal"}
          >
            Hello!
          </Text>
          <Text
            fontSize={{ base: "15px", md: "20px" }}
            fontWeight="500"
            color={"#000000"}
            mt="2"
          >
            Thank you for reaching out to us here. We appreciate your interest
            and support in our united community of diamond jewellery
            manufacturers. We mark seven+ years of growth and collaboration,
            guided by our motto{" "}
            <span style={{ fontWeight: "600" }}>
              {" "}
              "Grow together, shine together"{" "}
            </span>{" "}
            Your involvement contributes to our shared success. We showcase
            member profiles, extraordinary jewellery creations, and offer
            opportunities for retailers and related industries. By fostering
            partnerships and embracing positive changes, we strive to benefit
            our members and the jewellery industry as a whole.
          </Text>
        </Box>

        <Box
          mt="101px"
          h={{base:"auto",md:"106px"}}
          bgColor={"#FFFFFF"}
          border={"1px solid #E4E4E4"}
          borderRadius={"5px"}
          p="4"
        >
          <Box display={"flex"} gap="2">
            <Text fontSize={"20px"}>
              <LiaPhoneSolid />
            </Text>
            <Text fontSize={"15px"} fontWeight="400" color={"#000000"}>
              For further inquiries, please contact us at{" "}
            </Text>
          </Box>
          <Box mt="2" ml="7">
            <Text fontSize={"12px"} fontWeight="300" color={"#000000"}>
              Bangalore landline :
            </Text>
            <Text fontSize={"20px"} fontWeight="600" color={"#000000"}>
              080 8888223
            </Text>
          </Box>
        </Box>

        <Box textAlign={"center"} mt="10">
          <Text fontSize={"25px"} fontWeight="700" color={"#000000"}>
            OR
          </Text>
          <Text
            w="90%"
            m="auto"
            fontSize={"20px"}
            fontWeight="500"
            color={"#000000"}
          >
            Simply fill out the "Become a Member" form below and unlock
            exceptional opportunities.
          </Text>
        </Box>

        {/* Become a Member button start */}

        <Box mt="10" display={"flex"} justifyContent={"center"}>
          <Button
            bgGradient="linear(to-r, #ACEDC7, #A8FAF3)"
            _hover={"#A8FAF3"}
            px={"6"}
            color="#11232F"
            fontWeight={500}
            borderRadius={"30px"}
            onClick={handleBecomeMemberClick}
          >
            Become a Member
          </Button>
        </Box>

        {showForm && <MembershipForm onClose={handleCloseForm} />}

        <Box w={{ base: "70%", md: "100%" }} m="auto" mt="40" mb="20">
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            fontSize={{ base: "20px", md: "15px" }}
            color={"#999999"}
            fontWeight={400}
            display={{ base: "block", md: "flex" }}
            flexDirection={"coloum"}
            justifyContent={"space-evenly"}
          >
            <Stack>
              <ListHeader>
                <Box display={"flex"} gap="2" alignItems={"center"}>
                  <Text> Address</Text>
                  <Text>
                    <TiLocation />
                  </Text>
                </Box>
              </ListHeader>
              <Text>Bangalore</Text>
            </Stack>

            <Stack mt={{ base: "4", md: "0" }}>
              <ListHeader>
                <Box display={"flex"} gap="2" alignItems={"center"}>
                  <Text> Opening hours</Text>
                  <Text>
                    <BiSolidWatch />
                  </Text>
                </Box>
              </ListHeader>
              <Text>Monday: 9am – 6pm</Text>
              <Text>Tuesday: 9am – 6pm</Text>
              <Text>Wednesday: 9am – 6pm</Text>
              <Text>Thursday: 9am – 6pm</Text>
              <Text>Friday: 9am – 6pm</Text>
            </Stack>

            <Stack mt={{ base: "4", md: "0" }}>
              <ListHeader>
                <Box display={"flex"} gap="2" alignItems={"center"}>
                  <Text> Social media</Text>
                  <Text>
                    <TbHandClick />
                  </Text>
                </Box>
              </ListHeader>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                w="120px"
              >
                <Link>
                  <RoundedIcon>
                    <Icon as={FaInstagram} boxSize="12px" color="white" />
                  </RoundedIcon>
                </Link>
                <Link>
                  <RoundedIcon>
                    <Icon as={FaTwitter} boxSize="12px" color="white" />
                  </RoundedIcon>
                </Link>
                <Link>
                  <RoundedIcon>
                    <Icon as={FaFacebook} boxSize="12px" color="white" />
                  </RoundedIcon>
                </Link>
              </Box>
            </Stack>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUsTopSection;
