import { Box, Icon, Image, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CompanyDetailsAPI } from "../../Redux/action/company.action";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const RoundedIcon = ({ children }) => (
  <Box
    bg="#FFFFFF"
    borderRadius="full"
    display="flex"
    alignItems="center"
    justifyContent="center"
    w="30px"
    h="30px"
  >
    {children}
  </Box>
);

const ProfileTopSection = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { companyByID: companyDetails, loading } = useSelector(
    (state) => state.company
  );
  // console.log(companyDetails)

  useEffect(() => {
    dispatch(CompanyDetailsAPI(id));
  }, [dispatch, id]);

  return (
    <Box w="100%" py={{ base: 14, md: 10 }}>
      <Box
        data-aos="fade-up"
        data-aos-duration="2000"
        w={{ base: "90%", md: "80%" }}
        m="auto"
        py={10}
      >
        <Text
          color="#F9F9F9"
          fontSize={{ base: "30px", md: "40px" }}
          fontWeight="700"
          bgGradient="linear(to-l, #01FDA0, #01F2E6)"
          bgClip="text"
          textAlign={"center"}
        >
          DAB
        </Text>
        <Text
          color="#FFFFFF"
          fontSize={{ base: "30px", md: "40px" }}
          textAlign={"center"}
          fontWeight={500}
        >
          Member’s Company Profile
        </Text>

        {loading ? (
          <Box textAlign={"center"} mt="10">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
        ) : (
          <Box
            display={"flex"}
            flexDirection={{ base: "column-reverse", md: "row" }}
            key="index"
            mt="20"
            gap={{ base: "5", md: "none" }}
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <Box w={{ base: "100%", md: "60%" }} h="100%">
              <Text
                color="#FFFFFF"
                fontSize={{ base: "25px", md: "35px" }}
                fontWeight="700"
              >
                {companyDetails.name}
              </Text>

              <Box
                display={"flex"}
                flexDirection={{ base: "column", md: "row" }}
                gap="5"
                mt={2}
              >
                {companyDetails.owners &&
                  typeof companyDetails.owners === "object" &&
                  Object.values(companyDetails.owners).map((owner, index) => (
                    <Box key={owner.id}>
                      <Text
                        color="#FFFFFF"
                        fontSize={{ base: "15px", md: "16px" }}
                        fontWeight="500"
                      >
                        {owner.name}
                      </Text>
                      <Text
                        color="#FFFFFF"
                        fontSize={{ base: "15px", md: "16px" }}
                        fontWeight="500"
                      >
                        {owner.phone}
                      </Text>
                    </Box>
                  ))}
              </Box>

              <Text
                color="#FFFFFF"
                fontSize={{ base: "15px", md: "16px" }}
                mt={5}
                fontWeight="500"
              >
                Mail: {companyDetails.Email}
              </Text>
              <Text
                color="#FFFFFF"
                fontSize={{ base: "15px", md: "16px" }}
                fontWeight="500"
                mt="5"
                w={{ base: "90%", md: "80%" }}
              >
                Address: {companyDetails.address}
              </Text>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                w="120px"
                mt="4"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={companyDetails.instagram}
                >
                  <RoundedIcon>
                    <Icon as={FaInstagram} boxSize="18px" color="#11232F" />
                  </RoundedIcon>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={companyDetails.twitter}
                >
                  <RoundedIcon>
                    <Icon as={FaTwitter} boxSize="18px" color="#11232F" />
                  </RoundedIcon>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={companyDetails.facebook}
                >
                  <RoundedIcon>
                    <Icon as={FaFacebook} boxSize="18px" color="#11232F" />
                  </RoundedIcon>
                </a>
              </Box>
            </Box>

            <Box
              w={{ base: "100%", md: "40%" }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              position="relative"
              pt="5"
            >
              <Box
                display="flex"
                justifyContent="center !important"
                alignItems="center"
                position="relative"
              >
                <Image
                  w={{ base: "166px", md: "289px" }}
                  h={{ base: "148px", md: "258px" }}
                  src="/img/profileImageBg.png"
                />
                {companyDetails.logo && companyDetails.logo !== null ? (
                  <Image
                    w={{ base: "166px", md: "289px" }}
                    h={{ base: "148px", md: "258px" }}
                    src={companyDetails.logo}
                    position="absolute"
                    alignItems="center"
                    bottom="20px"
                    right={{ base: "20px", md: "40px" }}
                  />
                ) : (
                  <Image
                    w={{ base: "166px", md: "289px" }}
                    h={{ base: "148px", md: "258px" }}
                    src="/img/placeholder.png"
                    position="absolute"
                    alignItems="center"
                    bottom="20px"
                    right={{ base: "20px", md: "40px" }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProfileTopSection;
