import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  Image,
  Text,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link, NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

const Links = [
  {
    Name: "DAB Magazine",
    Path: "/DAB_Magazine",
  },
  {
    Name: "About us",
    Path: "/aboutUs",
  },
  {
    Name: "Events",
    Path: "/event",
  },
  {
    Name: "History of diamonds",
    Path: "/historyDiamond",
  },
  {
    Name: "Leadership Team",
    Path: "/aboutUs#LeaderSection",

  },
  {
    Name: "Contact us",
    Path: "/ContactUs",
  },
];

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const location = useLocation();


  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <>
      <Box
        w="100%"
        bgColor={"#F9F7F4"}
        position="fixed"
        top="0"
        left="0"
        opacity={visible ? 1 : 0}
        transform={`translateY(${visible ? "0" : "-100%"})`}
        transition="opacity 0.3s, transform 0.3s"
        zIndex="999"
      >
        <Box px={4} py={8} w={{ base: "100%", md: "90%" }} m="auto">
          <Flex h={16} justify={"space-between"} alignItems={"center"}>
            <Flex alignItems={"center"} gap="50px">
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <NavLink to={"/"}>
                  <Image
                    w={"120px"}
                    m={"auto"}
                    src={"img/logo.png"}
                    alt="HomeLogo"
                  />
                  {/* <Text
                      fontSize={"10px"}
                      textAlign={"center"}
                      color={"#0077bd"}
                      fontWeight={500}
                      w={{ base: "80px", md: "250px" }}
                    >
                      Diamond Association Bangalore
                    </Text> */}
                </NavLink>
                {/* <Text fontSize={"8px"} textAlign={"center"} color="#0077bd">
                  Shine Together... Grow Together
                </Text> */}
              </Box>
              <HStack
                as={"nav"}
                spacing={4}
                fontWeight={600}
                color={"#000000"}
                display={["none", "none", "none", "flex", "flex"]}
                gap="30px"
              >
                {Links.map((link) => (
                  <Text
                    fontWeight={600}
                    fontSize={"14px"}
                    key={link.Name}
                    borderBottom={
                      location.pathname === link.Path
                        ? "2px solid #F3CE82"
                        : "none"
                    }
                  >
                    <NavLink to={link.Path}>{link.Name}</NavLink>
                  </Text>
                ))}
              </HStack>
            </Flex>
            <Link to="/memberShipForm">
              <Button
                display={["none", "none", "none", "block", "block"]}
                bgGradient="linear(to-r, #ACEDC7, #A8FAF3)"
                _hover={"#A8FAF3"}
                px={"6"}
                color="#11232F"
                fontWeight={500}
                borderRadius={"30px"}
              >
                Become a Member
              </Button>
            </Link>
            <IconButton
              size={"lg"}
              fontSize="25px"
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={"Open Menu"}
              display={["block", "block", "block", "none", "none"]}
              mt={2}
              onClick={isOpen ? onClose : onOpen}
            />
          </Flex>

          {isOpen ? (
            <Box
              pb={4}
              mt={6}
              display={["block", "block", "block", "none", "none"]}
            >
              <Stack as={"nav"} spacing={4}>
                {Links.map((link) => (
                  <Text fontWeight={700} fontSize={"20px"} key={link.Name}>
                    <NavLink to={link.Path}>{link.Name}</NavLink>
                  </Text>
                ))}
              </Stack>
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
}
