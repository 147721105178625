import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const AssociationData = [
  {
    Image: "img/Asso1.png",
    Name: "Manish Kumar",
    AOSDuration: 500,
    Description:
      "This association has exceeded my expectations in every way. The events and networking opportunities have been invaluable, allowing me to forge meaningful connections and stay at the forefront of industry trends. The support and guidance from fellow members have been instrumental in my professional development. I am proud to be a part of this exceptional association.",
  },
  {
    Image: "img/Asso2.png",
    Name: "Sachin Saklecha ",
    AOSDuration: 1500,
    Description:
      "Being a part of this association has been transformative for my business. The invaluable connections, knowledge sharing, and support have accelerated my growth. From networking opportunities to staying updated on industry trends, this association has provided immense value. I highly recommend it for anyone looking to thrive in the industry.",
  },
  {
    Image: "img/Asso3.png",
    Name: "Chetan kumar mehta",
    AOSDuration: 2500,
    Description:
      "This association has exceeded my expectations in every way. The events and networking opportunities have been invaluable, allowing me to forge meaningful connections and stay at the forefront of industry trends. The support and guidance from fellow members have been instrumental in my professional development. I am proud to be a part of this exceptional association.",
  },
];

const AssociationSection = () => {
  return (
    <Box w={"90%"} m={"auto"} mt="40" mb="40">
      <Box
        // w={{ base: "100%", md: "80%" }}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        gap="30"
        m="auto"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <Text
          color="#000000"
          textAlign={"center"}
          fontSize={{ base: "40px", md: "50px" }}
          lineHeight={"normal"}
          fontWeight={500}
        >
          {" "}
          Insights from Our Association
        </Text>
        {/* <Image
          display={{ base: "none", md: "block" }}
          h="100px"
          src="img/ChatIcon.png"
        /> */}
      </Box>

      {/* Association Section Start */}
      <SimpleGrid
        columns={["1", "1", "2", "2", "3"]}
        spacing={10}
        m={"auto"}
        mt={{ base: "14", md: "10" }}
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        {AssociationData.map((el, index) => (
          <Box
            h={{base:"auto",md:"336px"}}
            w={{ base: "330px", md: "384px" }}
            m="auto"
            p="4"
            // data-aos="fade-up"
            // _hover={{
            //   WebkitTransform: "scale(1.1)",
            //   transition: "1s ease",
            //   transfrom: "scale(1.1)",
            //   msTransform: "scale(1.1)",
            // }}
            sx={{
              border: "1px solid",
              borderImage:
                "linear-gradient(to bottom, #E1CD19, #E18519, #E119A9)",
              borderImageSlice: "1",
            }}
          >
            <Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap="4"
              >
                <Image w="50px" h="50px" borderRadius={"50%"} src={el.Image} />
                <Text color="#000000" fontSize="17px" fontWeight={500}>
                  {el.Name}
                </Text>
              </Box>
              <Text mt="6" color="#000000" fontSize="16px" fontWeight={500}>
                {el.Description}
              </Text>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default AssociationSection;
