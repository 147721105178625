import { Box } from "@chakra-ui/react";
import React from "react";
import ProfileTopSection from "./ProfileTopSection";
import MagazinePage from "./MagazinePage";
import ProductSection from "./ProductSection";

const Profle = () => {
  return (
    <Box>
      <Box bgColor={"#11232F"}>
        <ProfileTopSection />
        <MagazinePage />
      </Box>
      <ProductSection />
    </Box>
  );
};

export default Profle;
