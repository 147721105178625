import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const CounterData = [
  {
    Number: 40,
    Text: "members connected, shaping a bright future together",
    AOSDuration: 1000,
  },
  {
    Number: 10,
    Text: "events a year, strengthening bonds and keeping everyone connected",
    AOSDuration: 1500,
  },
  {
    Number: 5,
    Text: "general meets, to explore new threats and opportunities",
    AOSDuration: 2000,
  },
];

const CounterSection = () => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <Box w="80%" m="auto" mt={{ base: "100px", md: "150px" }}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={[10,10,16,16,10]} m={"auto"}>
          {CounterData.map((el, index) => (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              key={index}
              w="251px"
              justifySelf="center"
              gap="5"
              data-aos="fade-up"
              data-aos-duration={el.AOSDuration}
            >
              <Text color="#4B4B4B" fontSize="50px" fontWeight="500">
                {counterOn && (
                  <CountUp start={0} end={el.Number} duration={3} delay={0} />
                )}{" "}
                +
              </Text>
              <Text
                color="#4B4B4B"
                fontSize={["20px","14px","20px","18px","25px"]}
                fontWeight="500"
                textAlign="center"
              >
                {el.Text}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </ScrollTrigger>
  );
};

export default CounterSection;
