import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FaSearch, FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";
// import VoiceRecognition from "./VoiceRecognition";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
AOS.init();

const SearchSection = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [voiceSearchEnabled, setVoiceSearchEnabled] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  console.log("listening", listening);

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      toast.warn("Please enter a search query.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      // Navigate with the search query
      navigate(`/DAB_Magazine?searchQuery=${encodeURIComponent(searchQuery)}`);
    }
  };

  useEffect(() => {
    const recognitionAvailable =
      "SpeechRecognition" in window || "webkitSpeechRecognition" in window;
    setVoiceSearchEnabled(recognitionAvailable);
  }, []);

  useEffect(() => {
    let timeoutId;
    if (transcript.trim() !== "") {
      clearTimeout(timeoutId);

      console.log(transcript);
      timeoutId = setTimeout(() => {
        if (listening) {
          setSearchQuery(transcript);
          navigate(
            `/DAB_Magazine?searchQuery=${encodeURIComponent(transcript)}`
          );
          resetTranscript();
        }
      }, 2000);
    }
  }, [transcript, navigate, resetTranscript]);

  return (
    <Box
      w={{ base: "100%", md: "90%" }}
      m={"auto"}
      mt={{ base: "50px", md: "150px" }}
    >
      <Text
        textAlign={"center"}
        color={{ base: "#11232E", md: "#000000" }}
        fontWeight={{ base: 600, md: 600 }}
        fontSize={{ base: "16px", md: "20px" }}
        ml={{ base: "none" }}
      >
        Uniting the Diamond Community on Our Website
      </Text>
      <Box display={"flex"} mt={{ base: "10", md: "14" }}>
        <Box
          display={"flex"}
          justifyContent={{ base: "none", md: "end" }}
          w={{ base: "80%", md: "68%" }}
          m={"auto"}
        >
          <Box>
            <Image
              mt={"-20px"}
              h={"100px"}
              src="img/Diamond1.png"
              alt="DiamondLogo"
              display={{ base: "none", md: "block" }}
            />
          </Box>
          <Box>
            <Text
              color={{ base: "#11232E", md: "#000000" }}
              fontSize={"50px"}
              fontWeight={{ base: 700, md: 800 }}
              textAlign={{ base: "center", md: "center" }}
            >
              Sparkling pathway to a{" "}
              <span style={{ color: "#6BD797" }}>Brighter</span> future..
            </Text>

            {/* Search section start */}
            <Box
              w={{ base: "100%" }}
              display={"flex"}
              flexDirection={{ base: "column", md: "row" }}
              gap="4"
              mt={4}
            >
              <InputGroup w={{ base: "100%", md: "80%" }}>
                <Input
                  h="49px"
                  borderRadius={"26px"}
                  fontFamily="Poppins"
                  border="1px solid #EFEEEA"
                  bgColor={"#FFFCFB"}
                  type="text"
                  value={searchQuery}
                  onChange={handleInputChange}
                  placeholder="eg. “Current president”"
                />
                <InputRightElement width="3.5rem" mt={1}>
                  {listening ? (
                    <Button
                      h="38px"
                      w="38px"
                      borderRadius={"50%"}
                      bgColor={"#11232F"}
                      _hover={"#11232F"}
                      color="#FFFFFF"
                      onClick={SpeechRecognition.stopListening}
                      disabled={listening}
                    >
                      <FaMicrophoneSlash style={{ fontSize: "20px" }} />
                    </Button>
                  ) : (
                    <Button
                      h="38px"
                      w="38px"
                      borderRadius={"50%"}
                      bgColor={"#11232F"}
                      _hover={"#11232F"}
                      color="#FFFFFF"
                      onClick={SpeechRecognition.startListening}
                      disabled={listening}
                    >
                      <FaMicrophone style={{ fontSize: "20px" }} />
                    </Button>
                  )}
                </InputRightElement>
              </InputGroup>

              <Button
                h="49px"
                w={{ base: "100%", md: "20%" }}
                bgColor="#11232F"
                color="#FFFFFF"
                _hover={"#11232F"}
                fontSize="15px"
                fontWeight={500}
                fontFamily="Poppins"
                borderRadius="42px"
                display="flex"
                alignItems="center"
                justifyContent={{ base: "center", md: "space-between" }}
                onClick={handleSearch}
              >
                Search
                <FaSearch style={{ marginLeft: "10px" }} />
              </Button>
            </Box>
            <Text
              color="#B9B9B9"
              fontSize="10px"
              fontWeight={500}
              fontFamily="Poppins"
              // ml="100px"
              mt="5px"
              display={{ base: "none", md: "block" }}
              // style={{textAlign:'center'}}
            >
              Manufacturers - Wholesalers - Gemstones - Job work
            </Text>
          </Box>
        </Box>

        {/* <Box
          w={{ base: "0%", md: "20%" }}
          display={"flex"}
          justifyContent={"end"}
          mt={"-80px"}
        >
          <Image
            display={{ base: "none", md: "block" }}
            h={"200px"}
            src="img/hands_Together.png"
            alt="TogetherLogo"
          />
        </Box> */}
      </Box>
    </Box>
  );
};

export default SearchSection;
