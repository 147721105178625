import React from "react";
import AboutUs from "../../Components/About/AboutUs";
const AboutUsPage = React.memo((props) => {
  return (
    <>
   <AboutUs />
    </>
  );
});

export default AboutUsPage;