import { Box, Flex, Image, SimpleGrid, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { EventAPI } from "../../Redux/action/event.action";
AOS.init();

const EventUpdateSection = () => {
  const dispatch = useDispatch();
  const { event: EventData, loading } = useSelector((state) => state.event);
  console.log("eventData", EventData);

  useEffect(() => {
    dispatch(EventAPI());
  }, [dispatch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <Box pt={{ base: "30px", md: "100px" }}>
      <Box>
        <Text
          color="#FCFCFC"
          fontSize={{ base: "20px", md: "15px" }}
          fontWeight="600"
          textAlign={"center"}
          pt="14"
        >
          Discover Past DAB Events Here
        </Text>
        <Text
          color="#F9F9F9"
          fontSize={{ base: "40px", md: "55px" }}
          fontWeight={{ base: "500", md: "700" }}
          textAlign={"center"}
        >
          Past Event Highlights
        </Text>
      </Box>

      {/* Event Section Start */}
      {loading ? (
        <Box textAlign={"center"} mt="10">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Box>
      ) : (
        <Box w={{ base: "90%", md: "80%" }} h={"auto"} m="auto">
          {EventData?.map((el) => (
            <Box py={{ base: "30px", md: "100px" }} key={el.id}>
              <Box
                display={"flex"}
                w="100%"
                flexDirection={{ base: "column", md: "row" }}
                gap={{ base: 5, md: 1 }}
              >
                <Box w={{ base: "100%", md: "50%" }} data-aos="fade-up">
                  <Box
                    display={"flex"}
                    flexDirection={{ base: "column", md: "row" }}
                    gap={{ base: 2, md: 5 }}
                    alignItems={{ base: "none", md: "center" }}
                  >
                    <Text
                      color="#F9F9F9"
                      fontSize={"35px"}
                      fontWeight="700"
                      textTransform={"uppercase"}
                    >
                      {el.title}
                    </Text>

                    <Text
                      bgGradient="linear(to-l, #01FDA0, #01F2E6)"
                      bgClip="text"
                      fontSize={{ base: "20px", md: "25px" }}
                      fontWeight="700"
                      textTransform={"uppercase"}
                    >
                      {new Date(el.start_date).getDate()} -{" "}
                      {formatDate(el.end_date)}
                    </Text>
                  </Box>
                  <Text color="#F9F9F9" fontSize={"25px"} fontWeight="700">
                    {el.location}
                  </Text>
                  <Text color="#F9F9F9" fontSize={"15px"} fontWeight="500">
                    {el.description}
                  </Text>
                </Box>
                <Box
                  w={{ base: "100%", md: "50%" }}
                  display={"flex"}
                  justifyContent={{ base: "center", md: "flex-end" }}
                >
                  {el.images && el.images.length > 0 ? (
                    <Image
                      w={{ base: "100%", md: "483px" }}
                      h={{ base: "223px", md: "320px" }}
                      src={el.images[0].file}
                    />
                  ) : (
                    <Image
                      w={{ base: "100%", md: "483px" }}
                      h={{ base: "223px", md: "320px" }}
                      src={"/img/placeholder.png"}
                    />
                  )}
                </Box>
              </Box>

              <Flex justifyContent="center">
                <SimpleGrid
                  columns={["1", "2", "2", "4", "4"]}
                  spacing={10}
                  mt={{ base: 5, md: "10" }}
                >
                    <Image
                    h={{ base: "159px", md: "159px" }}
                    w={{ base: "260px", md: "260px" }}
                    borderRadius={"10px"}
                    src={el?.images[1]?.file}
                    />
                
                  <Image
                    h={{ base: "159px", md: "159px" }}
                    w={{ base: "260px", md: "260px" }}
                    borderRadius={"10px"}
                    src={el?.images[2]?.file}
                  />
                  <Image
                    h={{ base: "159px", md: "159px" }}
                    w={{ base: "260px", md: "260px" }}
                    borderRadius={"10px"}
                    src={el?.images[3]?.file}
                  />
                  <Image
                    h={{ base: "159px", md: "159px" }}
                    w={{ base: "260px", md: "260px" }}
                    borderRadius={"10px"}
                    src={el?.images[4]?.file}
                  />
                </SimpleGrid>
              </Flex>

              <Box
                border={"1px solid #FFFFFF"}
                w="70%"
                m="auto"
                mt="10"
                display={{ base: "block", md: "none" }}
              ></Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default EventUpdateSection;
