import { Box } from "@chakra-ui/react";
import React from "react";
import SearchSection from "./SearchSection";
import CounterSection from "./CounterSection";
import EventCardSection from "./EventCardSection";
import AssociationSection from "./AssociationSection";
import OpportunitySection from "./OpportunitySection";
import TextDescriptionSection from "./TextDescriptionSection";

const Home = () => {
  return (
    <Box w="100%" m={"auto"}>
      <SearchSection />
      <CounterSection />
      <EventCardSection />
      <AssociationSection />
      <OpportunitySection />
      <TextDescriptionSection />
    </Box>
  );
};

export default Home;
