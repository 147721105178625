import { Box } from '@chakra-ui/react'
import React from 'react'
import ContactUsTopSection from './ContactUsTopSection'

const ContactUs = () => {
  return (
    <Box>
        <ContactUsTopSection />
    </Box>
  )
}

export default ContactUs