import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

const TopSection = () => {
  return (
    <Box w="100%" h={"auto"} mt="16">
      <Box w={{ base: "90%", md: "70%" }} h="100%" m="auto">
        <Text
          color="#000000"
          fontSize={{ base: "18px", md: "15px" }}
          fontWeight="600"
          textAlign={{ base: "center", md: "start" }}
        >
          About us
        </Text>

        <Box
          display={"flex"}
          flexDirection={{ base: "column-reverse", md: "row" }}
          mt="4"
          gap={5}
        >
          <Box w={{ base: "100%", md: "50%" }}>
            <Text
              color="#11232F"
              fontSize={{ base: "35px", md: "40px" }}
              fontWeight="700"
              lineHeight={"normal"}
            >
              Shine Together...
            </Text>
            <Text
              color="#11232F"
              fontSize={{ base: "35px", md: "40px" }}
              fontWeight="700"
              lineHeight={"normal"}
            >
              Grow Together...
            </Text>
            <Text
              color="#11232F"
              fontSize={"16px"}
              fontWeight="400"
              w={{ base: "full", md: "90%" }}
              mt="6"
            >
              {" "}
              At our Diamond Association, we embody the moto "Shine Together,
              Grow Together." We are dedicated to fostering collaboration,
              supporting growth, and creating a thriving environment for our
              members to shine and succeed in the diamond industry.
            </Text>
          </Box>
          <Box
            w={{ base: "100%", md: "50%" }}
            display={"flex"}
            alignItems={"center"}
          >
            <Image w="100%" src="img/AboutUs1.png" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TopSection;
