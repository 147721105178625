import { Box } from "@chakra-ui/react";
import React from "react";
import HistoryTopSection from "./HistoryTopSection";
import Top10Diamonds from "./Top10Diamonds";

const HistoryOfDiamonds = () => {
  return (
    <Box w="100%" bgColor={"#11232F"}>
      <HistoryTopSection />
      <Top10Diamonds />
    </Box>
  );
};

export default HistoryOfDiamonds;
