import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const UpcomingEventData = [
  {
    Image: "img/UpcomingEvent1.png",
    Description:
      "Prestigious Pan India Jewellery Exhibition. Showcase unique designs to HNI buyers & brides. Register now!",
    Email: "www.jewelleryworld.com",
    Address: "The St. Regis Mumbai",
    Date: "21-23-july-2023",
    AOSDuration: 500,
  },
  {
    Image: "img/UpcomingEvent2.png",
    Description:
      "Zak Jewels Expo: India's finest B2C jewellery exhibition featuring ex-quisite designs in Dia- monds, Rubies & more.",
    Email: "www.jzakjewelsexpo.com",
    Address: "Taj Coromandel CHENNAI",
    Date: "28-30-july-2023",
    AOSDuration: 800,
  },
  {
    Image: "img/UpcomingEvent3.png",
    Description:
      "GJIIF: Premier B2B gem & jewellery trade show, connecting domestic and global players in the industry.",
    Email: "www.gjiif.in",
    Address: "Chennai Trade Centre Chennai",
    Date: "29 sept - 01 oct-2023",
    AOSDuration: 1100,
  },
  {
    Image: "img/UpcomingEvent4.png",
    Description:
      "Exclusive Jewellery Collection at Pan India Jewellery Exhibition. Register now to showcase your designs.",
    Email: "www.jewelleryworld.com",
    Address: "Ahmedabad Ahmedabad",
    Date: "27-29-oct-2023",
    AOSDuration: 1400,
  },
  {
    Image: "img/UpcomingEvent1.png",
    Description:
      "Opulent Fine Jewellery Expo & Sale - Asia Jewels Show 2023 Hyderabad. Register for VIP Entry",
    Date: "25-27-nov-2023",
    Address: "Taj Krishna Hyderabad",
    Email: "www.allevents.in",
    AOSDuration: 1600,
  },
];

const UpcomingEvent = () => {
  return (
    <Box position={{ base: "none", md: "relative" }} pt={6}>
      <Image
        left={0}
        top={"300px"}
        display={{ base: "none", md: "block" }}
        position={{ base: "none", md: "absolute" }}
        src="img/diamond_Tile1.png "
      />

      <Box
        w={{ base: "80%", md: "93%" }}
        style={{ margin: "auto", paddingBottom: "50px" }}
      >
        <Text
          color="#FCFCFC"
          fontSize={{ base: "20px", md: "15px" }}
          fontWeight="600"
          textAlign={"center"}
          pt="14"
        >
          Watch this Space for latest happenings
        </Text>
        <Text
          color="#F9F9F9"
          fontSize={{ base: "40px", md: "55px" }}
          fontWeight={{ base: "500", md: "700" }}
          textAlign={"center"}
        >
          Upcoming Events
        </Text>

        {/* Event Card Start */}

        <SimpleGrid
          columns={["1", "1", "2", "4", "5"]}
          spacing={10}
          m={"auto"}
          mt={{ base: "14", md: "10" }}
        >
          {UpcomingEventData.map((el, index) => (
            <Box
              key={index}
              display={"flex"}
              flexDirection={"column"}
              //   alignItems={"center"}
              justifySelf={"center"}
              gap="1"
              bgGradient="linear(to-b,#03B1F0 , #63F2FF)"
              borderRadius={"10px"}
              p="2"
              pt={4}
              data-aos="fade-up"
              data-aos-duration={el.AOSDuration}
            >
              <Image w="100%" src={el.Image} />
              <Text
                color="#11232F"
                fontSize={{ base: "18px", md: "15px" }}
                fontWeight="600"
                //   noOfLines={{ base: 2, md: 3 }}
              >
                {el.Description}
              </Text>
              <Text
                color="#11232F"
                fontSize={{ base: "15px", md: "12px" }}
                fontWeight="400"
              >
                {el.Email}
              </Text>
              <Text
                color="#11232F"
                fontSize={{ base: "15px", md: "12px" }}
                fontWeight="400"
              >
                {el.Address}
              </Text>
              <Text
                color="#11232F"
                fontSize={{ base: "15px", md: "12px" }}
                fontWeight="700"
                textTransform={"uppercase"}
              >
                {el.Date}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default UpcomingEvent;
