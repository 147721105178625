import { GET_EVENT_ERROR, GET_EVENT_LOADING, GET_EVENT_SUCCESS } from "../actiontypes/event.types";

  
  const initdata = {
    loading: false,
    error: false,
    event: [],
  };
  
  const eventReducerFun = (state = initdata, { type, payload }) => {
    switch (type) {
      case GET_EVENT_LOADING: {
        return {
          ...state,
          loading: true,
          error: false,
        };
      }
  
      case GET_EVENT_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          event: payload,
        };
      }
  
      case GET_EVENT_ERROR: {
        return {
          ...state,
          loading: false,
          error: true,
        };
      }
 
  
      default: {
        return state;
      }
    }
  };
  
  export default eventReducerFun;
  