import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { localRoute } from "./localRoutes";
import HomePage from "../pages/homePage";
import Navbar from "../Components/Navbar/Navbar";
import AboutUsPage from "../pages/AboutUsPage/AboutUsPage";
import Footer from "../Components/Footer/Footer";
import EventPage from "../pages/EventPage/EventPage";
import ProfilePage from "../pages/ProfilePage/AboutUsPage/ProfilePage";
import ContactUsPage from "../pages/ContactUsPage/AboutUsPage/ContactUsPage";
import MemberShipFormPage from "../pages/MemberShipFormPage/MemberShipFormPage";
import HistoryDiamondPage from "../pages/HistoryDiamondsPage/HistoryDiamondPage";
import DABMagazinePage from "../pages/DABMagazinePage/DABMagazinePage";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function RouterComponent(props) {
  // const location = useLocation();

  return (
    <React.Fragment>
      <ScrollToTop />
      <Navbar />
      <div style={{ paddingTop: "120px" }}></div>
      <Routes>
        <Route exact path={localRoute.home} element={<HomePage />} />
        <Route
          exact
          path={localRoute.DABMagazine}
          element={<DABMagazinePage />}
        />
        <Route exact path={localRoute.aboutUs} element={<AboutUsPage />} />
        <Route exact path={localRoute.event} element={<EventPage />} />
        <Route
          exact
          path={localRoute.HistoryOfDiamond}
          element={<HistoryDiamondPage />}
        />
        <Route exact path={localRoute.profile} element={<ProfilePage />} />
        <Route exact path={localRoute.contactUs} element={<ContactUsPage />} />
        <Route
          exact
          path={localRoute.MemberShipForm}
          element={<MemberShipFormPage />}
        />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default RouterComponent;
