import { Box, Image, SimpleGrid, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CompanyDetailsAPI } from "../../Redux/action/company.action";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const ProductSection = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { companyByID: companyDetails, loading } = useSelector(
    (state) => state.company
  );
  // console.log(companyDetails);

  useEffect(() => {
    dispatch(CompanyDetailsAPI(id));
  }, [dispatch, id]);

  // Check if products_images is empty or not found
  const isProductsAvailable =
    companyDetails.products_images &&
    typeof companyDetails.products_images === "object" &&
    Object.values(companyDetails.products_images).length > 0;

  return (
    <Box w="100%">
      <Box
        w={{ base: "90%", md: "80%" }}
        m="auto"
        py="10"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <Text
          color="#11232F"
          fontSize={{ base: "30px", md: "40px" }}
          fontWeight="500"
          textAlign={"center"}
        >
          Our Products
        </Text>
        {loading ? (
          <Box textAlign={"center"} mt="10">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
        ) : (
          <Box>
            {isProductsAvailable ? (
              <SimpleGrid
                columns={["2", "2", "3", "3", "4"]}
                spacing={10}
                mt="10"
                data-aos="fade-up"
                data-aos-duration="4000"
              >
                {Object.values(companyDetails.products_images).map(
                  (imageUrl) => (
                    <Box m="auto" key={imageUrl.id}>
                      <Image
                        w={{ base: "134px", md: "206px" }}
                        src={imageUrl}
                      />
                    </Box>
                  )
                )}
              </SimpleGrid>
            ) : (
              <Text
                color="#11232F"
                fontSize={{ base: "20px", md: "30px" }}
                fontWeight="500"
                textAlign={"center"}
                mt="10"
              >
                Products Not Available...
              </Text>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProductSection;
