import React from "react";
import MembershipForm from "../../Components/ContactUs/MembershipForm";
const MemberShipFormPage = React.memo((props) => {
  return (
    <>
   <MembershipForm />
    </>
  );
});

export default MemberShipFormPage;