import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const CCTeamData = [
  {
    Image: "img/Team1.png",
    Name: "Mr. Manish Kumar ",
    Role: "Vice President",
    AOSDuration: 1000,
  },
  {
    Image: "img/Team2.png",
    Name: "Mr. Kunal Kothari",
    Role: "Secretary",
    AOSDuration: 1300,
  },
  {
    Image: "img/Team3.png",
    Name: "Mr. Ashish Jain ",
    Role: "Treasurer",
    AOSDuration: 1600,
  },
  {
    Image: "img/Team4.png",
    Name: "Mr. Subham Ranka  ",
    Role: "Director",
    AOSDuration: 1900,
  },
  {
    Image: "img/Team5.png",
    Name: "Mr. Sachin Kumar Saklecha",
    Role: "IPP",
    AOSDuration: 2100,
  },
  {
    Image: "img/Team6.png",
    Name: "Mr. Mukesh Mehta ",
    Role: "Director",
    AOSDuration: 1000,
  },
  {
    Image: "img/Team7.png",
    Name: "Mr. Vishal Jangra ",
    Role: "Director",
    AOSDuration: 1300,
  },
  {
    Image: "img/Team8.png",
    Name: "Mr. Nishank Seth",
    Role: "Director",
    AOSDuration: 1600,
  },
  {
    Image: "img/Team9.png",
    Name: "Mr. Darshan Chordia",
    Role: "Director",
    AOSDuration: 1900,
  },
];

const CCTeamSection = () => {
  return (
    <Box w="100%" h={"auto"} mt="20">
      <Box w={{ base: "70%", md: "90%" }} h="100%" m="auto">
        <Text
          color="#11232F"
          fontSize={{ base: "20px", md: "15px" }}
          fontWeight="600"
          textAlign={"center"}
          pt="10"
        >
          Our team
        </Text>
        <Text
          color="#11232F"
          fontSize={"33px"}
          fontWeight="600"
          textAlign={"center"}
          pt="2"
        >
          Meet Our Dynamic CC Team
        </Text>
        <SimpleGrid
          columns={["1", "1", "3", "3", "5"]}
          spacing={10}
          m={"auto"}
          mt={{ base: "14", md: "5" }}
        >
          {CCTeamData.map((el, index) => (
            <Box
              key={index}
              data-aos="fade-up"
              data-aos-duration={el.AOSDuration}
            >
              <Image w="100%" src={el.Image} />
              <Text
                mt="4"
                color="#474747"
                fontSize={{ base: "22px", md: "16px" }}
                fontWeight="500"
              >
                {el.Name}
              </Text>
              <Text
                color="#474747"
                fontSize={{ base: "20px", md: "15px" }}
                fontWeight="500"
              >
                {el.Role}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default CCTeamSection;
