import React from "react";
import HistoryOfDiamonds from "../../Components/HistoryOfDiamonds/HistoryOfDiamonds";

const HistoryDiamondPage = React.memo((props) => {
  return (
    <>
      <HistoryOfDiamonds />
    </>
  );
});

export default HistoryDiamondPage;