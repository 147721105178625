export const localRoute = {
  home: "/",
  DABMagazine:"/DAB_Magazine",
  aboutUs: "/aboutUs",
  event:"/event",
  HistoryOfDiamond:"/historyDiamond",
  profile:"/profile/:id",
  contactUs:"/contactUs",
  MemberShipForm:"/memberShipForm",
  notFound: "*",
  notFound500: "*",
};
