import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import OwnerDetailsForm from "./OwnerDetailsForm";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CompanyADDAPI } from "../../Redux/action/company.action";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
AOS.init();

const MembershipForm = ({ onClose }) => {
  const dispatch=useDispatch()
  const [companyDetails, setCompanyDetails] = useState({
    name: "",
    address: "",
    website: "",
    city: "",
  });

  const [owners, setOwners] = useState([]);

  const location = useLocation();
  console.log("owners", owners);

  useEffect(() => {
    setOwners([
      {
        name: "",
        phone: "",
        email: "",
        altContactNo: "",
      },
    ]);
  }, []);

  const handleAddOwner = () => {
    if (owners.length < 5) {
      setOwners((prevOwners) => [
        ...prevOwners,
        {
          name: "",
          phone: "",
          email: "",
          altContactNo: "",
        },
      ]);
    }
  };

  const handleDeleteOwner = (index) => {
    setOwners((prevOwners) => prevOwners.filter((owner, i) => i !== index));
  };

  const handleOwnerDetailsChange = (index, updatedOwnerDetails) => {
    setOwners((prevOwners) =>
      prevOwners.map((owner, i) => (i === index ? updatedOwnerDetails : owner))
    );
  };

  const handleCompanyDetailsChange = (event) => {
    const { name, value } = event.target;
    setCompanyDetails((prevCompanyDetails) => ({
      ...prevCompanyDetails,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const payload = {
      name: companyDetails.name,
      address: companyDetails.address,
      website: companyDetails.website,
      city: companyDetails.city,
      owners: owners
    };
    dispatch(CompanyADDAPI(payload));
  };

  return (
    <Box
      w={{
        base: "90%",
        md: location.pathname === "/memberShipForm" ? "70%" : "100%",
      }}
      m="auto"
      my="2"
    >
      <form onSubmit={handleSubmit}>
        <FormControl isRequired>
        <Box mt="10">
          <Box
            bgColor={"#F4F4F4"}
            boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
            borderRadius={"10px"}
            py="4"
            px="6"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <Text color={"#11232F"} fontSize={"12px"} fontWeight={500}>
              Company Details :
            </Text>
            {/* Company details form */}
            <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={10} mt="4">
              <Box>
                <FormLabel color={"#ABABAB"} fontSize={"14px"} fontWeight={500}>
                  Company Name{" "}
                </FormLabel>
                <Input
                  focusBorderColor="#ABABAB"
                  borderRadius={"5px"}
                  bgColor={"#FFFFFF"}
                  type="text"
                  name="name"
                  value={companyDetails.name}
                  onChange={handleCompanyDetailsChange}
                />
              </Box>
              <Box>
                <FormLabel color={"#ABABAB"} fontSize={"14px"} fontWeight={500}>
                  Company Address{" "}
                </FormLabel>
                <Input
                  focusBorderColor="#ABABAB"
                  borderRadius={"5px"}
                  bgColor={"#FFFFFF"}
                  type="text"
                  name="address"
                  value={companyDetails.address}
                  onChange={handleCompanyDetailsChange}
                />
              </Box>
              <Box>
                <FormLabel color={"#ABABAB"} fontSize={"14px"} fontWeight={500}>
                  Website
                </FormLabel>
                <Input
                  focusBorderColor="#ABABAB"
                  borderRadius={"5px"}
                  bgColor={"#FFFFFF"}
                  type="text"
                  name="website"
                  value={companyDetails.website}
                  onChange={handleCompanyDetailsChange}
                />
              </Box>
              <Box>
                <FormLabel color={"#ABABAB"} fontSize={"14px"} fontWeight={500}>
                  City
                </FormLabel>
                <Input
                  focusBorderColor="#ABABAB"
                  borderRadius={"5px"}
                  bgColor={"#FFFFFF"}
                  type="text"
                  name="city"
                  value={companyDetails.city}
                  onChange={handleCompanyDetailsChange}
                />
              </Box>
       
            </SimpleGrid>
          </Box>

          {/* Show only one OwnerDetailsForm initially */}
          {owners.map((owner, index) => (
            <OwnerDetailsForm
              key={index}
              index={index}
              ownerDetails={owner}
              onAddOwner={handleAddOwner}
              onDeleteOwner={() => handleDeleteOwner(index)}
              onOwnerDetailsChange={handleOwnerDetailsChange}
            />
          ))}

          <Box display={"flex"} justifyContent="center">
            <Button mt="10" type="submit" bgColor="#11232F" _hover={{ bgColor: "#11232F" }} color={"#FFFFFF"} borderRadius={"5px"}>
              Submit
            </Button>
          </Box>
        </Box>
        </FormControl>
      </form>
    </Box>
  );
};

export default MembershipForm;
