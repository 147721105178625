import { Box } from '@chakra-ui/react'
import React from 'react'
import SearchSection2 from './SearchSection2'

const DABMagazine = () => {
  return (
    <Box>
       <SearchSection2 /> 
    </Box>
  )
}

export default DABMagazine