import { Box, Image, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CompanyDetailsAPI } from "../../Redux/action/company.action";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const MagazinePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { companyByID: companyDetails, loading } = useSelector(
    (state) => state.company
  );
  // console.log(companyDetails);

  useEffect(() => {
    dispatch(CompanyDetailsAPI(id));
  }, [dispatch, id]);

  return (
    <Box w="100%" py={{ base: 14, md: 10 }}>
      <Box
        w={{ base: "90%", md: "80%" }}
        m="auto"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <Text
          color="#F9F9F9"
          fontSize={{ base: "30px", md: "40px" }}
          fontWeight={500}
          textAlign={"center"}
        >
          Magazine Page{" "}
        </Text>

        {loading ? (
          <Box textAlign={"center"} mt="10">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
        ) : (
          <Box>
            <Box
              data-aos="fade-up"
              data-aos-duration="1500"
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              gap={5}
              mt="5"
              justifyContent="center"
              alignItems="center"
            >
              {companyDetails.directory_images &&
                typeof companyDetails.directory_images === "object" &&
                Object.values(companyDetails.directory_images).map(
                  (imageUrl) => (
                    <Box key={imageUrl.id}>
                      <Image
                        w={{ base: "333px", md: "450px" }}
                        src={imageUrl}
                      />
                    </Box>
                  )
                )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MagazinePage;
