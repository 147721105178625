import {
  Box,
  Flex,
  Image,
  Stack,
  Text,
  chakra,
  VisuallyHidden,
  SimpleGrid,
  Button,
} from "@chakra-ui/react";
import { FaInstagram, FaFacebook } from "react-icons/fa";
import React from "react";
import { Link } from "react-router-dom";

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      color={"White"}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const Footer = () => {
  return (
    <Box w="100%" h={{ base: "auto", md: "310px" }} bgColor="#11232F">
      <SimpleGrid
        w="90%"
        m="auto"
        py={10}
        display={"flex"}
        flexDirection={{ base: "Column", md: "row" }}
        justifyContent={"space-between"}
        gap={10}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={{ base: "8", md: "16" }}
          w={{ base: "70%", md: "33%" }}
        >
          <Box>
            <Link to="/">
              <Image src="img/FooterLogo.png" w={"280px"} />
            </Link>
          </Box>

          <Stack display={"flex"} direction={"row"} spacing={1}>
            <Text
              color={"#FFFFFF"}
              fontSize={{ base: "20px", md: "17px" }}
              fontFamily={"Poopins"}
              fontWeight={500}
            >
              Follow us on
            </Text>
            <SocialButton label={"Facebook"} href={"#"}>
              <Text fontSize={20}>
                <FaFacebook />
              </Text>
            </SocialButton>

            <SocialButton label={"Instagram"} href={"#"}>
              <Text fontSize={20}>
                <FaInstagram />
              </Text>
            </SocialButton>
          </Stack>
        </Box>
        <Box w={{ base: "70%", md: "33%" }}>
          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 3 }}
            spacing={{ base: 2, md: 4 }}
            textAlign={{ base: "none", md: "center" }}
            color={"#FFFFFF"}
            fontSize={{ base: "25px", md: "18px" }}
            fontFamily={"Poopins"}
            fontWeight={500}
          >
            <Stack align={"justify"}>
              <Link to={"/DAB_Magazine"}>DAB Magazine</Link>
            </Stack>

            <Stack align={"justify"}>
              <Link to={"/aboutUs"}>About us</Link>
              <Link to={"/event"}>Events</Link>
              <Link to={"#"}>News</Link>
            </Stack>

            <Stack align={"justify"}>
              <Link to={"#"}>Leadership Team</Link>
              <Link to={"/ContactUs"}>Contact </Link>
            </Stack>
          </SimpleGrid>
        </Box>
        <Box
          w={{ base: "90%", md: "33%" }}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={{ base: "8", md: "16" }}
        >
          <Link to="/memberShipForm">
            <Button
              display={{ base: "none", md: "block" }}
              bgGradient="linear(to-r, #ACEDC7, #A8FAF3)"
              _hover={"#A8FAF3"}
              px={"6"}
              color="#11232F"
              fontWeight={500}
              borderRadius={"30px"}
            >
              Become a Member
            </Button>
          </Link>
          <Text
            color={"#9C9C9C"}
            fontSize={"17px"}
            fontFamily={"Poopins"}
            fontWeight={500}
          >
            Crafted by Dream Designers
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Footer;
