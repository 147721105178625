import React from "react";
import Profle from "../../../Components/ProfilePage/Profle";
const ProfilePage = React.memo((props) => {
  return (
    <>
   <Profle />
    </>
  );
});

export default ProfilePage;