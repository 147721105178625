import { Box, Text } from "@chakra-ui/react";
import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const ImpactSection = () => {
  return (
    <Box
      w="100%"
      h={"auto"}
      mt="20"
      bgGradient="linear(to-t, #00F1E9, #00FD9A)"
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
      py="16"
    >
      <Box
        w={{ base: "90%", md: "54%" }}
        h="80%"
        m="auto"
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        data-aos="fade-up"
      >
        <Text
          color="#000000"
          fontSize={{ base: "18px", md: "15px" }}
          fontWeight="600"
        >
          How it started!
        </Text>
        <Text
          pt="2"
          color="#000000"
          fontSize={{ base: "40px", md: "55px" }}
          fontWeight="900"
        >
          From Idea to Impact
        </Text>
        <Text
          color="#000000"
          fontSize={{ base: "25px", md: "25px" }}
          fontWeight="600"
        >
          Driving Bangalore's Diamond Industry since 2016
        </Text>
        <Text
          color="#000000"
          fontSize={{ base: "18px", md: "15px" }}
          fontWeight="500"
          mt="10"
        >
          Diamond association Bangalore, established in 2016 initiated by Ratan
          Srisrimal, Sunil Surana, Lalith Saklecha, Chetan Mehta and other
          senior members under their common and collective thought and
          encouragement by other manufacturers and wholesalers in Bangalore
          market with a purpose to establish Bangalore as a hub for
          Manufacturing and wholesale supply of diamond and diamond jewellery.
          In simple words to create a gate way to South Indian market and also
          become a platform for our fellow members to overcome the hindrances
          faced by them in loose diamond supply, diamond jewellery equipment and
          modernisation of craftsmanship and crafts man issues faced by members.
          Diamond association Bangalore in-turn over these 7 years has taken up
          a number of initiatives in this direction.
        </Text>
      </Box>
    </Box>
  );
};

export default ImpactSection;
