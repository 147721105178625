import React from "react";
import ContactUs from "../../../Components/ContactUs/ContactUs";
const ContactUsPage = React.memo((props) => {
  return (
    <>
   <ContactUs />
    </>
  );
});

export default ContactUsPage;