import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const CCTeamData = [
  {
    Image: "img/Leader1.png",
    Name: "Mr. Ratan Chand ",
    Timeline: "2016-2017",
    AOSDuration: 1000,
  },
  {
    Image: "img/Leader2.png",
    Name: "Mr. Sunil Surana",
    Timeline: "2017-2018",
    AOSDuration: 1300,
  },
  {
    Image: "img/Leader3.png",
    Name: "Mr. Chetan Kumar Mehta",
    Timeline: "2018-2019",
    AOSDuration: 1600,
  },
  {
    Image: "img/Leader4.png",
    Name: "Mr. Ashok Rathod",
    Timeline: "2019-2020",
    AOSDuration: 1900,
  },
  {
    Image: "img/Leader5.png",
    Name: "Mr. Sachin Saklecha",
    Timeline: "2020-2022",
    AOSDuration: 2100,
  },
];

const LeadersSection = () => {
  useEffect(() => {
    const url = window.location.href;
    if (url.includes('#LeaderSection')) {
      const contactSection = document.getElementById('LeaderSection');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Box id="LeaderSection"  w="100%" h={"auto"} my="10">
      <Box w={{ base: "70%", md: "90%" }} h="100%" m="auto">
        <Box m="auto" w={{ base: "100%", md: "50%" }}>
          <Text
            color="#11232F"
            fontSize={{ base: "20px", md: "15px" }}
            fontWeight="600"
            textAlign={"center"}
          >
            Legacy Leaders
          </Text>
          <Text
            color="#11232F"
            fontSize={"33px"}
            fontWeight="600"
            textAlign={"center"}
            pt="2"
          >
            Honoring the Past Presidents of the Diamond Association Bangalore
          </Text>
        </Box>
        <SimpleGrid
          columns={["1", "1", "3", "3", "5"]}
          spacing={10}
          m={"auto"}
          mt={{ base: "14", md: "5" }}
        >
          {CCTeamData.map((el, index) => (
            <Box
              key={index}
              data-aos="fade-up"
              data-aos-duration={el.AOSDuration}
            >
              <Image w="100%" src={el.Image} />
              <Text
                mt="4"
                color="#474747"
                fontSize={{ base: "22px", md: "16px" }}
                fontWeight="500"
              >
                {el.Name}
              </Text>
              <Text
                color="#474747"
                fontSize={{ base: "20px", md: "15px" }}
                fontWeight="500"
              >
                {el.Timeline}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default LeadersSection;
