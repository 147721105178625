import {
  GET_COMPANY_BYID,
  GET_COMPANY_ERROR,
  GET_COMPANY_LOADING,
  GET_COMPANY_SUCCESS,
} from "../actiontypes/company.types";

const initdata = {
  loading: false,
  error: false,
  company: [],
  companyByID:[]
};

const companyReducerFun = (state = initdata, { type, payload }) => {
  switch (type) {
    case GET_COMPANY_LOADING: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case GET_COMPANY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        company: payload,
      };
    }

    case GET_COMPANY_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case GET_COMPANY_BYID: {
      return {
        ...state,
        loading: false,
        error: false,
        companyByID: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default companyReducerFun;
