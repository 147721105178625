import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

const HistoryTopSectionData = [
  {
    Image1: "/img/UpcomingHeritage.png",
    Image2: "/img/UpcomingHeritage2.png",
    Desc1:
      "Golconda diamonds, mined in the Godavari delta of Andhra Pradesh and Telangana, India, were highly valued. Golconda Fort in Hyderabad was a key centre for diamond enhancement and trading. These Type IIa diamonds, pure and nitrogen-free, are large and exceptionally clear, earning the reputation of being diamonds of the first water. The term 'Golconda Diamond' signifies unmatched quality. Production declined after 2,000 years of mining, and now Golconda diamonds are considered antique, rare, and precious. Notable examples include the Kohinoor, Nassak Diamond, Hope Diamond, Idol's Eye, Daria-i-Noor, Regent, Dresden Green, and Orlov. Other remarkable diamonds like the Florentine Yellow, Akbar Shah, Nizam, and Great Mogul are no longer traceable.",
    Desc2:
      "The Golconda Diamond Mine, dating back to 1725 CE, holds a significant place in history and is part of the esteemed collection of Pieter van der Aa, a renowned Dutch publisher known for his meticulous preparation of maps and atlases. The Golconda region, recognised for its diamond-rich deposits, became famous for producing some of the world's most remarkable diamonds. Pieter van der Aa, with his dedication to detail and accuracy, meticulously documented and preserved the rich heritage of the Golconda Diamond Mine. His collection stands as a testament to the rich history and enduring legacy of this renowned diamond mining region.",
  },
];

const HistoryTopSection = () => {
  return (
    <Box
      w={{ base: "90%", md: "75%" }}
      style={{ margin: "auto", paddingBottom: "50px" }}
      pt={{ base: "30px", md: "100px" }}
      //   bgColor={"red"}
    >
      <Box>
        <Text
          color="#FFF"
          fontSize={{ base: "30px", md: "40px" }}
          fontWeight={{ base: "500", md: "700" }}
          textAlign={"center"}
        >
          Unveiling India's Diamond Heritage
        </Text>
      </Box>
      {HistoryTopSectionData?.map((el, index) => (
        <Box mt={2} key={index}>
          <Box>
            <Image width={"100%"} src={el.Image1} />
            <Text
              mt={4}
              color="#FFF"
              fontSize={{ base: "15px", md: "20px" }}
              fontWeight={{ base: "300", md: "300" }}
              textAlign={"justify"}
              fontStyle={"normal"}
            >
              {el.Desc1}
            </Text>
          </Box>

          <Box
            mt={4}
            display={"flex"}
            flexDirection={{ base: "column", md: "row" }}
            gap={4}
          >
            <Image width={"100%"} src={el.Image2} />
            <Text
              color="#FFF"
              fontSize={{ base: "15px", md: "20px" }}
              fontWeight={{ base: "300", md: "300" }}
              textAlign={"justify"}
              fontStyle={"normal"}
            >
              {el.Desc2}
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default HistoryTopSection;
