import React from "react";
import Home from "../../Components/HomePage/Home";
const HomePage = React.memo((props) => {
  return (
    <>
      <Home />
    </>
  );
});

export default HomePage;
