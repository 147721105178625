import React from "react";
import Event from "../../Components/EventPage/Event";

const EventPage = React.memo((props) => {
  return (
    <>
      <Event />
    </>
  );
});

export default EventPage;