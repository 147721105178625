import {
  GetAPICALL,
  PostAPICALL,
} from "../../Config/Functions/getFun";

import { toast } from "react-toastify";
import {
  GET_COMPANY_BYID,
  GET_COMPANY_ERROR,
  GET_COMPANY_LOADING,
  GET_COMPANY_SUCCESS,
} from "../actiontypes/company.types";

export const CompanyADDAPI = (payload) => (dispatch) => {
  PostAPICALL(`members/`, payload)
    .then((r) => {
      toast.success("Company Added Successfully.", {
        position: "top-left",
        theme: "colored",
        autoClose: 3000,
      });
      // dispatch(CompanyAPI());
    })
    .catch((err) => {
      console.log(err);
      toast.error("Company Not Added.", {
        position: "top-left",
        theme: "colored",
        autoClose: 3000,
      });
    });
};

export const CompanyAPI = (searchName) => (dispatch) => {
  dispatch({ type: GET_COMPANY_LOADING });
  GetAPICALL(`companies/?q=${searchName}`)
    .then((r) => {
      dispatch({ type: GET_COMPANY_SUCCESS, payload: r.results });
    })
    .catch(() => {
      dispatch({ type: GET_COMPANY_ERROR });
    });
};

export const CompanyDetailsAPI = (id) => (dispatch) => {
  dispatch({ type: GET_COMPANY_LOADING });
  GetAPICALL(`companies/${id}`)
    .then((res) => {
      dispatch({ type: GET_COMPANY_BYID, payload: res });
    })
    .catch((err) => {
      console.log(err)
    });
};





